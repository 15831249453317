.modalContent {
  height: 420px;
  .left {
    width: 300px;
    padding: 12px;
    overflow-y: scroll;
  }
  .menu {
    .menuPanel {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }
  }
}

.mathNode {
  width: 48px;
  height: 48px;
  background: #108ee9;
  border-radius: 2px;
  .label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: white;
  }
}

.dimensionNode {
  height: 38px;
  background: #87d068;
  border-radius: 2px;
  .label {
    display: flex;
    height: 100%;
    padding: 0 12px;
    align-items: center;
    font-size: 16px;
    color: white;
  }
  .placeHolder {
    width: 80px;
  }
}

.switchNode {
  background: #f50;
  padding: 12px;
  min-width: 220px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: white;
    gap: 12px;
    .label {
      display: flex;
      flex: 1;
    }
    .button {
      margin-top: 2px;
      cursor: pointer;
    }
  }
  .cases {
    display: flex;
    flex-direction: column;
    .case {
      position: relative;
      display: flex;
      flex-direction: column;
      border-top: 3px dotted rgba(0, 0, 0, 0.12);
      padding-top: 5px;
      margin-top: 5px;
      .content {
        max-height: 10000px;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        overflow: hidden;
        .header {
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;
          .collapsibleTrigger {
            display: flex;
            flex-direction: row;
            flex: 1;
            cursor: pointer;
            .caseText {
              color: white;
            }
            .caret {
              width: 16px;
            }
          }
          .select {
            background: rgba(0, 0, 0, 0.1);
            width: 120px;
          }
        }
        .output {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 12px;
          color: white;
          font-size: 16px;
          padding: 6px 0;
          .arrow {
            display: flex;
            flex: 1;
            margin-left: 5px;
          }
          .input {
            background: white;
            width: 120px;
          }
        }
      }
    }
  }
}

.outputNode {
  height: 38px;
  background: #f57d1a;
  border-radius: 2px;
  .label {
    display: flex;
    height: 100%;
    padding: 0 12px;
    align-items: center;
    font-size: 16px;
    color: white;
  }
}

.edgeButtonContainer {
  transition: opacity 0.3s;
}