.modalContent {
  display: flex;
  flex-direction: row;

  .formContainer {
    display: flex;
    flex: 1;
    padding: 10px;
    overflow: auto;
    .form {
      width: 100%;
      height: 400px;
      :global(.ant-collapse-header) {
        padding: 0 0 8px 0;
      }
      :global(.ant-collapse-item) {
        :global(.ant-collapse-content-box) {
          padding: 0;
          :global(.ant-form-item):last-child {
            margin-bottom: 0;
          }
        }
        margin-bottom: 16px;
      }
    }
    :global(.ant-select-selection-overflow-item):nth-last-child(2) .comma {
        display: none;
    }
    :global(.ant-select-selection-overflow-item):first-child .tagLabel {
      margin-left: 8px;
    }
  }
  .previewContainer {
    padding: 10px;
    .preview {
      width: 100%;
      height: 200px;
      background: rgba(0, 0, 0, 0.01);
      border: 1px dashed rgba(0, 0, 0, 0.1);
      padding: 10px;
      overflow: hidden;
      .refreshChartButtonContainer {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: center;
      }
      .diagram {
        canvas {
          z-index: 10 !important;
        }
        width: 100%;
        height: 100%;
      }
    }
  }
  .numericalInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    .checkbox {
      // width: 14px;
      // margin-right: 5px;
    }
  }
  .dimensionInput {
    display: flex;
    flex-direction: row;
  }
  .stringInput {
    display: flex;
    flex-direction: row;
  }
  .dimensionInput.hasElementAfterSelect {
    :global(.ant-select) :global(.ant-select-selector) {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    button:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
  }
}
